@use 'common'

.wrapper
	//

.main

	@media (min-width: common.$break62)
		display: grid
		grid-template-columns: 1fr common.$portfolioPage-rightColumn-width
		justify-content: flex-start
		gap: 3.75rem
		position: relative
		z-index: 0

.content
	display: flex
	flex-direction: column
	justify-content: center
	height: calc(100vh - common.$headerDesktopHeight)
	z-index: 0

	&In
		display: flex
		flex-direction: column

.title
	margin: 0
	margin-bottom: 3.75rem
	text-transform: uppercase
	text-align: center
	font-size: 2.5em
	font-weight: 400
	line-height: 1.175

	@media (min-width: common.$break30)
		font-size: 3.4375em

	@media (min-width: common.$break62)
		margin: unset

	@media (min-width: common.$break75)
		font-size: 6rem

.text
	max-width: 37.5rem
	margin-inline: auto
	text-align: center
	font-size: 1.5em

.portfolio
	z-index: 0 // for posters that appears on top of content

	@media (min-width: common.$break62)
		max-width: 28.125rem
		width: 100%
		margin-inline: auto
