@use 'sass:math'

// Normalizes height to 1em while preserving aspect ratio
=normalizeSize($intrinsicWidth, $intrinsicHeight)
	$height: 1em

	width: math.div($intrinsicWidth, $intrinsicHeight) * $height
	height: $height

.icon
	// Add all svg icons and corresponding dimensions

	&-go
		+normalizeSize(512, 512)

	&-arrow
		+normalizeSize(25, 25)

	&-playIcon
		+normalizeSize(24, 24)
