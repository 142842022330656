@use 'common'

.wrapper
	//

.main
	--CoverVideo-main-aspectRatio: 4 / 3

	aspect-ratio: var(--CoverVideo-main-aspectRatio)
	position: relative
	background-color: #c7c7c7

	@media (min-width: common.$break30)
		--CoverVideo-main-aspectRatio: 16 / 9

	&::before
		@supports not (aspect-ratio: 1)
			content: ''
			position: relative
			display: block
			padding-top: calc(100% / (var(--CoverVideo-main-aspectRatio)))

.video
	position: absolute
	inset: 0
	object-fit: cover
	width: 100%
	height: 100%
	z-index: 0

	@supports not (inset: 0)
		top: 0
		right: 0
		bottom: 0
		left: 0

.is_loading
	display: flex
	justify-content: center
	align-items: center
	width: 100%
	height: 100%

.loadingElement
	transform: translate(-50%, 50%)
	width: 8vmax
	height: 8vmax
	border-right: .25rem solid #000000
	border-radius: 100%
	animation: spinRight 0.8s linear infinite
	z-index: 0

	&::before, &::after
		content: ''
		width: 6vmax
		height: 6vmax
		display: block
		position: absolute
		top: calc(50% - 3vmax)
		left: calc(50% - 3vmax)
		border-left: 3px solid #000000
		border-radius: 100%
		animation: spinLeft 800ms linear infinite
		z-index: 1

	&::after
		width: 4vmax
		height: 4vmax
		top: calc(50% - 2vmax)
		left: calc(50% - 2vmax)
		border: 0
		border-right: 2px solid #000000
		animation: none

@keyframes spinLeft
	from
		transform: rotate(0deg)
	to
		transform: rotate(720deg)

@keyframes spinRight
	from
		transform: rotate(360deg)
	to
		transform: rotate(0deg)
