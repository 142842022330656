@use 'common'

.wrapper
	//

.description
	+common.description
	margin-top: 1.25rem
	max-width: 47.5rem

.logos
	display: flex
	justify-content: center
	flex-wrap: wrap
	gap: 1.25rem
	margin-top: 1.25rem

.logo
	//
