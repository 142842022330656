@use 'common'

.wrapper
	--container-horizontal-spacing: var(--page-horizontal-spacing)

	max-width: calc(var(--container-width) + 2 * var(--container-horizontal-spacing))
	width: 100%
	margin-left: auto
	margin-right: auto
	padding-left: var(--container-horizontal-spacing)
	padding-right: var(--container-horizontal-spacing)

	&.view_size_normal
		--container-width: var(--content-width-normal)

	&.view_size_narrow
		--container-width: var(--content-width-narrow)

	&.view_size_wide
		--container-width: var(--content-width-wide)

	&.view_disableGutters
		--container-horizontal-spacing: 0rem
