@use 'common'

.wrapper
	//

.main
	//

.year

	&List
		@media (min-width: common.$break30)
			display: grid
			grid-template-columns: auto auto
			justify-content: center
			column-gap: 1.25rem
			row-gap: 5.625rem

	&Section
		@media (min-width: common.$break30)
			display: contents

		&:not(:first-child)
			margin-top: 4.0625rem

	&Label
		font-family: common.$rubik
		font-weight: 500
		font-size: 1.875em
		margin-bottom: .625rem

		@media (min-width: common.$break30)
			font-size: 3.125em
			line-height: 1
			margin: 0

.show

	&List
		//

	&Title
		text-transform: uppercase
		text-decoration: underline
		font-family: common.$rubik
		font-size: 1.125em
		font-weight: 500
		color: #767676

		&:first-child
			margin-top: .125rem

		&:not(:first-child)
			margin-top: 1.5625rem

		@media (min-width: common.$break30)
			text-decoration: none

			&:not(:first-child)
				margin-top: 3.125rem

			&:hover
				color: #000000

.actions
	margin-top: 2.5rem
	@media (min-width: common.$break30)
		margin-top: 5rem
		text-align: center

.link
	+common.link
