@use 'common'

.wrapper
	&.is_overflowing
		.title
			font-size: 2.5em

			@media (min-width: common.$break22)
				font-size: 2.8125em

			@media (min-width: common.$break48)
				font-size: 5.625em

			@media (min-width: common.$break62)
				font-size: 6em

	&.is_primary
		.title
			@media (min-width: common.$break75)
				font-size: 7.5em
				line-height: 1.115

	&.is_secondary
		//

.title
	margin: 0
	font-size: 2.8125em
	line-height: 1
	text-align: center
	font-weight: 400
	text-transform: uppercase

	@media (min-width: common.$break22)
		font-size: 3.4375em

	@media (min-width: common.$break48)
		font-size: 6em
		line-height: 1.145
