@use 'common'

$underlineThicknes: 1px

=hideBreakElement
	br
		display: none

.wrapper

	&.is_breakableFrom_always
		//

	&.is_breakableFrom_768
		@media not all and (min-width: common.$break48)
			+hideBreakElement

	&.is_breakableFrom_992
		@media not all and (min-width: common.$break62)
			+hideBreakElement

	&.is_underline_appears
		+common.linkHover($underlineThicknes)

	&.is_underline_disappears
		+common.linkUnderline($underlineThicknes)

	&.is_underline_graduallyDisapears
		+common.linkHover($underlineThicknes, false)

	a:hover &, a:focus-visible &
		&.is_underline_graduallyDisapears
			+common.underlineGraduallyDisapears($underlineThicknes)

		&.is_underline_disappears
			// @TODO: solve focus-visible
			+common.linkHover($underlineThicknes, 'underlineDisappears')
