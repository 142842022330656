@use 'common'

$imageWidth: 15.625rem
$firstNameOffset: 2.1875rem
$middleColumnWidth: 18.75rem
$contentVerticalSpacing: 1.25rem

.wrapper
	//

.title
	max-width: var(--content-width-narrow)
	margin-inline: auto
	margin-bottom: 3.125rem

	@media (min-width: common.$break48)
		margin-bottom: 6.25rem

.list
	text-align: center

	@media (min-width: common.$break62)
		text-align: initial

.item
	&:not(:first-child)
		margin-top: 6.25rem

	@media (min-width: common.$break62)
		display: grid
		grid-template-columns: 1fr $middleColumnWidth 1fr

		&::after
			content: ''
			display: block

		&:nth-child(odd)
			.nameWrapper
				justify-self: end

		&:nth-child(even)
			.nameWrapper
				order: 3

			.contentWrapper
				order: 2

		&:first-child
			.nameWrapper
				margin-right: calc(($middleColumnWidth - $imageWidth) / 2)

			.contentWrapper
				max-width: initial

.name
	font-size: 2.5rem
	line-height: 1
	font-weight: 400
	margin: 0
	text-transform: uppercase

	&Wrapper
		--Members-distance: 0.1

		margin-bottom: .625rem

		@media (min-width: common.$break62)
			width: min-content
			padding-right: $firstNameOffset
			align-self: center
			transform: translateY(calc(1px * var(--Members-offset) * var(--Members-distance)))

	@media (min-width: common.$break75)
		font-size: 4rem

.contentWrapper
	max-width: $imageWidth
	width: 100%
	margin-inline: auto

.firstName
	@media (min-width: common.$break62)
		text-align: end
		width: 100%
		margin-left: $firstNameOffset

.lastName
	@media (min-width: common.$break62)
		text-align: start

.position
	font-size: 1.125rem
	line-height: 1.16
	text-transform: uppercase
	font-family: common.$rubik
	margin-top: $contentVerticalSpacing

	@media (min-width: common.$break62)
		text-align: center
		padding-left: $firstNameOffset

.image
	$aspectRatio: 3 / 4
	position: relative
	aspect-ratio: $aspectRatio

	&::before
		@supports not (aspect-ratio: 1)
			content: ''
			display: block
			padding-top: calc(100% / $aspectRatio)

	@media not all and (min-width: common.$break62)
		margin-top: $contentVerticalSpacing

.contact
	width: 100%
	list-style: none
	padding: unset

	&Link
		+common.linkUnderline(1px)
