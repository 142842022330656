@use 'common'
@use 'sass:color'

.wrapper
	--ShowPage-carousel-offset: calc((100% - #{common.$contentWidth-normal}) / 2)

	display: flex
	flex-direction: column
	justify-content: space-between
	margin-bottom: 2.5rem

	@media (min-width: common.$break30)
		margin-bottom: 5rem

.hero
	position: relative

	// The shadow for carousel
	// &::before, &::after
	// 	content: ''
	// 	position: absolute
	// 	top: 0
	// 	bottom: 0
	// 	width: calc((100% - #{common.$contentWidth-normal}) / 2)
	// 	height: 100%
	// 	z-index: 1

	// &::before
	// 	left: 0
	// 	background: linear-gradient(to right, #E5E5E5 80%, transparent)

	// &::after
	// 	right: 0
	// 	background: linear-gradient(to left, #E5E5E5 80%, transparent)

	&In
		display: flex
		justify-content: space-between
		gap: 1.25rem

		.slide
			flex-shrink: initial

.content
	//

.title
	margin: unset
	font-size: 2.5em
	line-height: 1.18
	font-weight: 500
	font-family: common.$rubik, common.$headingFont

	@media (min-width: common.$break30)
		font-size: 3.125em

	@media (min-width: common.$break48)
		max-width: 41.875rem

.synopsis
	margin-top: .9375rem

	@media (min-width: common.$break30)
		width: 23.75rem

.carousel
	+common.hideHorizontalScrollbar

	position: relative
	scroll-snap-type: x mandatory
	display: flex
	align-items: center
	padding-right: var(--page-horizontal-spacing)
	z-index: 0

	&.is_mobile
		margin-top: 3.125rem
		@media (min-width: common.$break33)
			display: none

	&.is_desktop

		@media not all and (min-width: common.$break33)
			.is_content
				max-width: initial
				width: 100%

			.slide
				&:not(:first-child)
					padding-left: 0
					display: none

.navigation
	margin-top: 1.25rem

.content
	//

.slide
	--ShowPage-slide-width: 85%
	--ShowPage-slide-withMaxHeight: min(calc(var(--ShowPage-image-width) / var(--ShowPage-image-height) * calc(100vh - (#{common.$headerDesktopHeight} + 7rem))), var(--ShowPage-slide-width))

	width: var(--ShowPage-slide-withMaxHeight)
	flex-shrink: 0
	scroll-snap-align: start
	padding-left: var(--page-horizontal-spacing)

	&In
		width: 100%

	&.is_content
		max-width: var(--ShowPage-slide-width)
		align-self: start

		.slide
			margin-right: 0
			scroll-snap-align: initial
			align-self: center

	@media (min-width: common.$break30)
		--ShowPage-slide-width: 45%

		&.is_content
			--ShowPage-slide-width: 90%

	@media (min-width: common.$break48)
		--ShowPage-slide-width: calc(var(--ShowPage-image-width) * 1px)

	@media (min-width: common.$break64)

		&.is_desktop_hidden
			display: none

	@media not all and (min-width: common.$break64)
		&.is_mobile_hidden
			display: none

	@media (min-width: common.$contentWidth-normal)
		--ShowPage-slide-width: calc(var(--ShowPage-image-width) * 1px + var(--ShowPage-carousel-offset))
		padding-left: calc((100% - #{common.$contentWidth-normal}) / 2 + .5rem)
		margin-right: calc((((100% - #{common.$contentWidth-normal})) / 2) * -1 + var(--page-horizontal-spacing))

		&:last-child
			margin-right: calc((100% - #{common.$contentWidth-normal}) / 2 + .5rem)

		&.is_content
			width: common.$contentWidth-normal
			padding-left: calc((100vw - #{common.$contentWidth-normal} - var(--page-horizontal-spacing)) / 2)

.image
	display: flex
	justify-content: center
	align-items: center
	width: 100%
	position: relative
	aspect-ratio: calc(var(--ShowPage-image-width) / var(--ShowPage-image-height))
	background-color: #808080

	&::before
		@supports not (aspect-ratio: 1)
			content: ''
			display: block
			padding-top: calc(100% / (var(--ShowPage-image-width) / var(--ShowPage-image-height)))

	@media (min-width: common.$break48)
		max-width: calc(var(--ShowPage-image-width) * 1px)

.playIcon
	display: flex
	width: 3.75rem
	font-size: 3.75rem
	aspect-ratio: 1 / 1
	z-index: 1
	color: common.$bodyColor

.button
	display: flex
	margin-inline: auto
	margin-top: 1.25rem
	text-transform: uppercase
	font-family: common.$rubik, common.$bodyFont
	font-size: .875em
	font-weight: 500

	+common.linkUnderline

.button, .slideIn
	background-color: unset
	border: unset
	outline: unset
	padding: initial

.informations
	margin-top: 5rem
	display: grid
	grid-template-columns: 1fr
	gap: 1.25rem
	row-gap: 2.5rem

	@media (min-width: common.$break30)
		grid-template-columns: 1fr 1fr
		gap: 2.5rem

	@media (min-width: common.$break48)
		margin-top: 7rem

.column

	&Label
		font-family: common.$rubik, common.$headingFont
		font-weight: 500
		text-transform: uppercase
		margin-block: 0 .625rem

	&Description
		//
