@use 'common'
@use 'sass:math'

.wrapper
	padding-block: 1.875rem

	@media (min-width: common.$break30)
		padding-block: 3.125rem

		&.is_homepage
			//

		&.is_portfolio
			//

.main
	display: grid
	grid-template-columns: 1fr 1fr 1fr
	align-items: center

.column
	display: flex
	align-items: center
	gap: 1.25rem

	&.is_right
		justify-content: flex-end

	@media (min-width: common.$break62)
		gap: 5rem

.links
	display: none

	@media (min-width: common.$break48)
		display: flex
		gap: 1.25rem
		list-style: none
		padding: unset

.link
	display: none

	@media (min-width: common.$break48)
		+common.linkHover(2px, underlineAppears)
		display: initial
		text-transform: uppercase
		font-family: common.$rubik
		font-size: 1.125em

.socialLinks
	//

.logo
	$aspectRatio: calc(var(--Header-logo-width) / var(--Header-logo-height))
	--Header-logo-maxHeight: 1.875rem

	position: relative
	aspect-ratio: $aspectRatio
	width: calc(var(--Header-logo-maxHeight) * $aspectRatio)
	// margin-inline: auto

	&::before
		@supports not (aspect-ratio: 1)
			content: ''
			display: block
			padding-top: calc(100% / $aspectRatio)

	@media (min-width: common.$break30)
		--Header-logo-maxHeight: 3.125rem

	@media (min-width: common.$break62)
		--Header-logo-maxHeight: 3.875rem
