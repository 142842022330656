@use 'common'

.wrapper
	//

.list
	display: flex
	justify-content: center
	gap: .3125rem

.item
	//

.link
	display: flex
	align-items: center
	gap: .75rem

	&:hover, &:focus-visible
		.label
			+common.underlineGraduallyDisapears(2px)

.image
	$aspectRatio: var(--SocialLinks-image-width) / var(--SocialLinks-image-height)
	--Header-logo-maxHeight: 1.6875rem

	position: relative
	aspect-ratio: $aspectRatio
	width: calc(var(--Header-logo-maxHeight) * $aspectRatio)
	height: var(--Header-logo-maxHeight)

	&::before
		@supports not (aspect-ratio: 1)
			content: ''
			display: block
			padding-top: calc(100% / $aspectRatio)

	&.is_normal
		//

	&.is_small
		--Header-logo-maxHeight: 1.125rem

.label
	font-family: common.$rubik
	font-size: 1.125rem
	line-height: 1.6
	text-transform: uppercase

	+common.linkHover(2px, graduallyDisapears)
