@use '../vars'

$defaultUnderline: .125rem

=link($underlineThickness: $defaultUnderline)
	+linkHover($underlineThickness, 'underlineAppears')
	font-size: 1.125em
	font-weight: 500
	line-height: 1.45
	text-transform: uppercase
	font-family: vars.$rubik

	@media (min-width: vars.$break48)
		font-size: 1.5em

=linkUnderline($thickness: $defaultUnderline, $isHover: true)
	+linkUnderlineStyles($thickness)

	@if $isHover
		+linkHover($thickness, underlineDisappears)

=linkHover($thickness: $defaultUnderline, $underlineType: 'underlineAppears') // underlineAppears | underlineDisappears | graduallyDisapears

	@if $underlineType == underlineAppears
		+linkUnderlineStyles($thickness)
		text-decoration-color: transparent

		&:hover
			text-decoration-color: #000000

		// @TODO: focus-visible selector is seperated bcs of safari...
		&:focus-visible
			text-decoration-color: #000000

	@else if $underlineType == underlineDisappears
		+linkUnderlineStyles($thickness)

		&:hover
			text-decoration-color: transparent

		// @TODO: focus-visible selector is seperated bcs of safari...
		&:focus-visible
			text-decoration-color: transparent

	@else if $underlineType == graduallyDisapears // graduated animation underline
		display: inline
		background-image: linear-gradient(to right,#000000 99%)
		background-size: 0 $thickness
		background-position: left 100%
		background-repeat: no-repeat
		transition: 1.1s cubic-bezier(0.19, 1, 0.22, 1)
		outline: none

		&:hover
			+underlineGraduallyDisapears($thickness)

		// @TODO: focus-visible selector is seperated bcs of safari...
		&:focus-visible
			+underlineGraduallyDisapears($thickness)

=underlineGraduallyDisapears($thickness: $defaultUnderline)
	background-size: 100% $thickness
	background-position: 0% 100%

=linkUnderlineStyles($thickness: $defaultUnderline)
	text-underline-offset: .1875rem
	text-decoration: underline
	text-decoration-thickness: $thickness
	text-decoration-style: solid
	transition: text-decoration-color .2s
