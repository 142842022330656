@use 'common'

$gap: .625em
$descriptionWidth: 50rem

.wrapper
	--Collaboration-description-marginTop: 2.5rem

	@media (min-width: common.$break62)
		--Collaboration-description-marginTop: 4.6875rem

.title
	max-width: 43.75rem
	margin-inline: auto
	position: relative
	z-index: 0

.image
	--Collaboration-image-aspectRatio: calc(var(--Collaboration-image-width) / var(--Collaboration-image-height))

	position: relative
	aspect-ratio: var(--Collaboration-image-aspectRatio)
	max-width: calc(var(--Collaboration-image-width) * 1px)

	&::before
		@supports not (aspect-ratio: 1)
			content: ''
			display: block
			padding-top: calc(100% / var(--Collaboration-image-aspectRatio))

.images
	display: grid
	&.is_mobile
		max-width: var(--content-width-narrow)
		margin-inline: auto
		grid-template-columns: 1fr 1fr
		gap: $gap

		@media (min-width: common.$contentWidth-normal)
			display: none

		.image
			&.is_1
				$image1-aspectRatio: 5 / 11
				width: 80%
				grid-column: 2
				grid-row: 3
				aspect-ratio: $image1-aspectRatio
				margin-bottom: 3.75em

				&::before
					@supports not (aspect-ratio: 1)
						content: ''
						display: block
						padding-top: calc(100% / #{$image1-aspectRatio})

			&.is_2
				width: 85%
				grid-column: 2
				margin-top: (8.75em - $gap)
				margin-bottom: (7.5em - $gap)

			&.is_3
				grid-row: 1
				grid-column: 2

			&.is_4
				$image4-aspectRatio: 2 / 5
				margin-top: 2.1875em
				grid-column: 1
				grid-row: 1 / 3
				aspect-ratio: $image4-aspectRatio

				&::before
					@supports not (aspect-ratio: 1)
						content: ''
						display: block
						padding-top: calc(100% / #{$image4-aspectRatio})

			&.is_5
				grid-column: 1
				grid-row: 3
				width: 55%
				justify-self: end
				align-self: end

	&.is_desktop
		--HeroDynamic-distance: 0.15

		.image
			width: 100%

		&.is_upper
			grid-template-columns: auto 28.75rem auto
			margin-bottom: -15rem

			.image
				position: relative

				&.is_2
					grid-column: 3
					grid-row: 3 / 6
					transform: translateY(calc(-1px * var(--Collaboration-offset) * var(--HeroDynamic-distance)))

				&.is_3
					--HeroDynamic-distance: 0.05

					right: 45%
					grid-column: 3
					grid-row: 1
					transform: translateX(calc(1px * var(--Collaboration-offset) * var(--HeroDynamic-distance)))

				&.is_2, &.is_3
					justify-self: end

				&.is_4
					margin-top: 1.25rem
					grid-row: 1 / 5
					grid-column: 1
					transform: translateY(calc(1px * var(--Collaboration-offset) * var(--HeroDynamic-distance)))

		&.is_inner
			grid-template-columns: 1fr $descriptionWidth 1fr

			.image

				&.is_1
					--HeroDynamic-distance: 0.03

					justify-self: center
					order: 2
					margin-top: 1.25rem
					transform: translateX(calc(-1px * var(--Collaboration-offset) * var(--HeroDynamic-distance)))

				&.is_5
					align-self: end
					margin-bottom: calc(var(--Collaboration-description-marginTop) * -1)
					order: 1
					transform: translateY(calc(1px * var(--Collaboration-offset) * var(--HeroDynamic-distance)))

		@media not all and (min-width: common.$contentWidth-normal)
			display: none

.description
	+common.description
	max-width: $descriptionWidth
	margin-top: var(--Collaboration-description-marginTop)
	margin-inline: auto

.actions
	margin-top: 1.875rem
	text-align: center

.link
	+common.link
