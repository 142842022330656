@use 'common'

.wrapper
	//

.section
	&:not(:first-child):not(.is_coverVideo)
		margin-top: 8rem

		@media (min-width: common.$break62)
			margin-top: 15rem

	&.is_coverVideo
		margin-top: .625rem

		@media (min-width: common.$break62)
			margin-top: 3.75rem
