@use 'common'

.wrapper
	width: 100%
	display: flex
	justify-content: space-between
	padding: 0 3.125rem
	pointer-events: none

	&.view_slidesPerView
		&_1
			max-width: common.$contentWidth-normal

.button
	--CarouselCardsNavigation-button-size: 1.5625rem

	width: var(--CarouselCardsNavigation-button-size)
	height: var(--CarouselCardsNavigation-button-size)
	font-size: 1em
	padding: 0
	background-color: transparent
	border: initial
	pointer-events: auto
	visibility: hidden
	opacity: 0
	transition-property: visibility, opacity
	transition-duration: .2s
	font-size: .9em
	z-index: 2

	&.is_visible
		visibility: initial
		opacity: 1

	&.view_previous
		transform: scaleX(-1)

	&.view_next
		//
