@use 'common'

$columnHorizontalSpacing: .9375rem

=label
	text-transform: uppercase
	font-family: common.$rubik
	margin-block: 0
	margin-bottom: 1rem
	font-weight: 500

.wrapper
	padding-top: 6.25rem
	padding-bottom: 2.5rem
	font-size: 1.125em

	@media (min-width: common.$break48)
		padding-top: 11.25rem
		padding-bottom: 5rem

.section

	&.is_above

		.sectionIn
			display: flex
			flex-wrap: wrap
			row-gap: 4.0625rem

			@media (min-width: common.$break30)
				margin-inline: -($columnHorizontalSpacing)

			@media (min-width: common.$break48)
				justify-content: center

	&.is_below
		margin-top: 5rem

		@media (min-width: common.$break48)
			.sectionIn
				display: flex
				justify-content: space-between
				align-items: flex-end

		@media (min-width: common.$break62)
			margin-top: 20.625rem

.column
	width: 100%

	@media (min-width: common.$break30)
		padding-inline: $columnHorizontalSpacing
		width: 50%

	@media (min-width: common.$break48)
		width: 33%

	@media (min-width: common.$break62)
		width: 25%

.title
	font-size: 1.5625em
	line-height: 1.4
	text-align: center
	text-transform: uppercase
	font-family: common.$rubik
	font-weight: 300
	margin: 0
	margin-bottom: 2.1875rem

	@media (min-width: common.$break62)
		font-size: 2.5rem
		margin-bottom: 3.75rem

.address

	&Label
		+label

	&Info
		font-style: normal

	&Link
		+common.linkUnderline(1px, false)
		outline: none

.contactMembers
	display: contents

.generalContact
	&Label
		+label

	&Info
		margin-bottom: 1.25rem

	&Link
		+common.linkUnderline(1px, false)

.member

	&Position
		+label

	&Info
		//

	&ContactLink
		+common.linkUnderline(1px, false)

.socialsWrapper
	display: flex
	margin-bottom: .9375rem

	@media (min-width: common.$break48)
		margin-bottom: 0

.logo
	--Footer-logo-aspectRatio: calc(var(--Footer-logo-width) / var(--Footer-logo-height))
	position: relative
	aspect-ratio: var(--Footer-logo-aspectRatio)
	width: calc(var(--Footer-logo-width) * 1px)

	&::before
		@supports not (aspect-ratio: 1)
			content: ''
			display: block
			padding-top: calc(100% / (var(--Footer-logo-aspectRatio)))

	@media (min-width: common.$break48)
		&Wrapper
			display: grid
			grid-template-columns: auto auto
			order: -1
			gap: 2.1875rem

.info
	max-width: 14.375rem
	margin-top: .625rem
	font-family: common.$rubik
	font-size: 1em
	text-transform: uppercase

.copyright
	text-transform: uppercase
	font-family: common.$rubik
	margin-top: 2.5rem

	&Link
		+common.linkUnderline
