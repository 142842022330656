@use 'common'

.wrapper
	display: flex
	justify-content: center
	align-content: center
	flex-direction: column
	width: 100%
	height: 100vh
	text-align: center

	@media (min-width: common.$break62)
		font-size: 2em

.title
	margin: 1em 0 0.5em
	line-height: 1.05
	text-transform: uppercase

	&Status
		font-size: 2em

.returnLink
	width: max-content
	margin-inline: auto
	+common.link
