$bodyColor: #E5E5E5

$sangBleuSans: SangBleu Sans
$cinzel: "Cinzel"
$rubik: "Rubik"

$bodyFont: $sangBleuSans, sans-serif
$headingFont: $cinzel, $rubik, $bodyFont

$break22: 22rem
$break30: 30rem
$break33: 33rem
$break48: 48rem
$break62: 62rem
$break64: 64rem
$break75: 75rem

$contentWidth-normal: 90rem

$portfolioPage-rightColumn-width: 28.125rem

$headerDesktopHeight: 10.125rem
