@use 'common'

$fontSize: 1.125em

.wrapper

	&.is_block
		position: relative
		z-index: 0

		.poster
			position: absolute
			top: var(--PortFolio-poster-percentagePosition)

			&.is_hovered

				&.is_left
					left: 0

				&.is_right
					right: 0

	&.is_page
		.poster
			position: fixed
			top: calc(#{common.$headerDesktopHeight} + var(--PortFolio-poster-percentagePosition))

			&.is_hovered

				&.is_left
					left: var(--PortFolio-poster-percentagePosition)

				&.is_right
					right: calc(#{common.$portfolioPage-rightColumn-width} + var(--PortFolio-poster-percentagePosition))
.year

	&List
		@media (min-width: common.$break30)
			display: grid
			grid-template-columns: auto auto
			justify-content: center
			column-gap: 1.25rem
			row-gap: 5.625rem

	&Section
		@media (min-width: common.$break30)
			display: contents

		&:not(:first-child)
			margin-top: 4.0625rem

	&Label
		font-family: common.$rubik
		font-weight: 500
		font-size: 1.875em
		margin: 0
		margin-bottom: .625rem

		@media (min-width: common.$break30)
			font-size: 3.125em
			line-height: 1
			margin: 0

.show
	$underlineThickness: .125rem

	&Link
		display: block
		outline: unset

		&:hover &, &:focus-visible
			.showTitle
				// +common.linkHover(2px)

		&:first-child
			margin-top: .125rem

		&:not(:first-child)
			margin-top: 1.5625rem

		@media (min-width: common.$break30)

			&:not(:first-child)
				margin-top: 3.125rem

	&List
		//

	&Item
		//

	&Title
		text-transform: uppercase
		font-family: common.$rubik
		font-size: $fontSize
		font-weight: 500
		margin: 0
		+common.linkHover(2px)

	&Synopsis
		font-size: $fontSize
		margin-top: .625rem

.posters
	position: absolute
	inset: 0
	z-index: -1
	@media not all and (min-width: common.$break62)
		display: none

.poster
	--PotzgolioList-poster-aspectRatio: calc(var(--PortfolioList-poster-width) / var(--PortfolioList-poster-height))
	aspect-ratio: var(--PotzgolioList-poster-aspectRatio)
	width: 100%
	max-width: var(--PortfolioList-poster-maxWidth)
	z-index: 0
	visibility: hidden
	opacity: 0
	transform: scale(.6)

	&::before
		@supports not (aspect-ratio: 1)
			content: ''
			display: block
			padding-top: calc(100% / (var(--PotzgolioList-poster-aspectRatio)))

	&.is_sizeType_default
		//

	&.is_sizeType_small
		max-width: 200px

	&.is_sizeType_big
		max-width: 400px

	&.is_type_default
		//

	&.is_type_vertical
		--PotzgolioList-poster-aspectRatio: 3 / 5

	&.is_type_horizontal
		--PotzgolioList-poster-aspectRatio: 5 / 3

	&.is_hovered
		visibility: initial
		opacity: 1
		transform: scale(1)
		transition-property: visibility, opacity, transform
		transition-duration: .2s
		transition-timing-function: ease-in-out
